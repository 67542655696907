exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-imaginarium-vol-1-js": () => import("./../../../src/pages/imaginarium-vol-1.js" /* webpackChunkName: "component---src-pages-imaginarium-vol-1-js" */),
  "component---src-pages-imaginarium-vol-2-js": () => import("./../../../src/pages/imaginarium-vol-2.js" /* webpackChunkName: "component---src-pages-imaginarium-vol-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-midi-crate-checkout-js": () => import("./../../../src/pages/midi-crate-checkout.js" /* webpackChunkName: "component---src-pages-midi-crate-checkout-js" */),
  "component---src-pages-midi-crate-js": () => import("./../../../src/pages/midi-crate.js" /* webpackChunkName: "component---src-pages-midi-crate-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-mc-js": () => import("./../../../src/pages/success-mc.js" /* webpackChunkName: "component---src-pages-success-mc-js" */)
}

